import { useState } from "react";
import "./HidePinButton.css";
import icon from "../../../assets/images/pin.png";
import iconActive from "../../../assets/images/pin_active.png";
import useScreenSize from "../../../hooks/useScreenSize";
import { showPin } from "../../../shared/utils/common-utils";

const HidePinButton = () => {
  const [viewPin, setViewPin] = useState(true);
  const { isMobile } = useScreenSize();
  window.viewPin = viewPin;
  const onChange = (v) => {
    setViewPin(v);
    showPin(v);
  };

  return (
    <div className="action-group">
      <div className={`action-btn alone relative`} onClick={() => onChange(!viewPin)}>
        <div
          style={{
            background: "#e31b53",
            width: 24,
            height: !viewPin ? 2 : 0,
            position: "absolute",
          }}
          className="top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-45"
        ></div>
        <div
          className={viewPin ? "active" : ""}
          style={{
            width: 25,
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          <img src={viewPin ? iconActive : icon} alt="icon" />
        </div>
      </div>
    </div>
  );
};

export default HidePinButton;
