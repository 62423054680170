import './ActionButtons.scss';
import { CloseDashboardIcon, DashboardIcon, DownIcon, FullScreenIcon, HelpIcon, LeftIcon, MinusIcon, PlusIcon, RightIcon, SpinIcon, UpIcon, VolumeIcon, VrIcon, ShareIcon, HomeIcon } from "./icons";
import useScreenSize from '../../hooks/useScreenSize';
import { useEffect, useRef, useState } from 'react';
import ContactButtons from './components/ContactButton';
import { useContext } from 'react';
import AppContext from '../../shared/contexts/AppContext';
import useFullscreen from '../../hooks/useFullscreen';
import { FacebookShareButton } from 'react-share';
import { Tooltip } from '@mui/material';
import { t } from 'i18next';
import { BASE_URL, MAX_ZOOM, MIN_ZOOM } from '../../shared/constants/const';
import { Alert } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import HidePinButton from './components/HidePinButton';
import SwitchLanguage from './components/SwitchLanguage';

let spinInterval = null;
let checkNodeTimeout = null;
let checkPhoto2DTimeout = null;

const ActionButtons = () => {
  const [open, setOpen] = useState(false);
  const [autoSpin, setAutoSpin] = useState(false);
  const { isMd, isMobile } = useScreenSize();
  const { soundOn, setSoundOn, setManualOpen, currentNode, setCurrentNode, photo2DViewingUrl, setPhoto2DviewingUrl, } = useContext(AppContext);
  const { isFullscreen, setIsFullscreen } = useFullscreen();
  const [alertOpen, setAlertOpen] = useState(false);
  const nodeCacheRef = useRef(currentNode);
  const [effect, setEffect] = useState(1);

  useEffect(() => {
    if (currentNode != nodeCacheRef.current) {
      setCurrentNode(nodeCacheRef.current);
      console.log(`currentNode: ${nodeCacheRef.current}`);
    }
  }, [effect]);



  useEffect(() => {
    const handleKeyDown = (event) => {
      panoPlayer = window.panoHelper?.pano;
      switch (event.key) {
        case 'ArrowUp':
          onMoveUp();
          break;
        case 'ArrowDown':
          onMoveDown();
          break;
        case 'ArrowLeft':
          onMoveLeft()
          break;
        case 'ArrowRight':
          onMoveRight();
          break;
        default:
          break;
      }
    };

    const handleClick = (event) => {
      if (spinInterval || !!autoSpin) {
        clearInterval(spinInterval);
        setAutoSpin(false);
      }

      checkChangeNode();
      checkPhoto2D();
    };

    setTimeout(() => {
      let viewerElement = document.getElementById('hotspots');
      if (viewerElement) {
        viewerElement.addEventListener('click', handleClick);
        viewerElement.addEventListener('touchstart', handleClick);
      }
    }, 3000);

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      let viewerElement = document.getElementById('hotspots');
      if (viewerElement) {
        viewerElement.removeEventListener('click', handleClick);
        viewerElement.removeEventListener('touchstart', handleClick);
      }
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    // Always show on PC
    setOpen(!isMd);
  }, [isMd]);

  useEffect(() => {
    if (!autoSpin) {
      clearInterval(spinInterval);
    } else {
      clearInterval(spinInterval);
      onSpin();
      spinInterval = setInterval(() => {
        onSpin();
      }, 500);
    }
  }, [autoSpin]);

  let panoPlayer = window.panoHelper?.pano;

  const checkChangeNode = () => {
    handleChangeNode();

    if (!!checkNodeTimeout) {
      clearTimeout(checkNodeTimeout);
    }

    checkNodeTimeout = setTimeout(() => {
      checkNodeTimeout = null;
      handleChangeNode();
    }, 300);
  }

  const handleChangeNode = () => {
    const nodeId = window.panoHelper?.pano.getCurrentNode();
    if (nodeId != nodeCacheRef.current) {
      // The setCurrentNode doesn't work here due to setTimeout => Use nodeCacheRef to cache that value
      // Use effect flag to fire change event
      nodeCacheRef.current = nodeId;
      setEffect(prev => prev + 1);
    }
  }

  const checkPhoto2D = () => {
    if (window.photo2DUrl != photo2DViewingUrl) {
      setPhoto2DviewingUrl(window.photo2DUrl);
    }

    if (checkPhoto2DTimeout) {
      clearTimeout(checkPhoto2DTimeout);
    }

    checkPhoto2DTimeout = setTimeout(() => {
      checkPhoto2DTimeout = null;
      if (window.photo2DUrl != photo2DViewingUrl) {
        setPhoto2DviewingUrl(window.photo2DUrl);
      }
    }, 200);
  }

  const onZoom = isUp => {
    var currentValue = panoPlayer.getFov();
    if (isUp) {
      currentValue = Math.max(currentValue - 5, MIN_ZOOM);
    } else {
      currentValue = Math.min(currentValue + 5, MAX_ZOOM);
    }

    panoPlayer.setFov(currentValue);
  }

  const getPosition = () => {
    var x = panoPlayer.getPan();
    var y = panoPlayer.getTilt();
    var fov = panoPlayer.getFov();
    return { x, y, fov };
  }

  const moveTo = (obj) => {
    panoPlayer.moveTo(obj.x, obj.y, obj.fov);
  }

  const onMoveUp = () => {
    const pos = getPosition();
    pos.y = Math.min(pos.y + 5, 90);
    moveTo(pos);
  }

  const onMoveDown = () => {
    const pos = getPosition();
    pos.y = Math.max(pos.y - 5, -90);
    moveTo(pos);
  }

  const onMoveLeft = () => {
    const pos = getPosition();
    pos.x = pos.x - 5;
    moveTo(pos);
  }

  const onSpin = () => {
    const pos = getPosition();
    pos.x = pos.x - 5;
    panoPlayer.moveTo(pos.x, pos.y, pos.fov, 0.5);
  }

  const onMoveRight = () => {
    const pos = getPosition();
    pos.x = pos.x + 5;
    moveTo(pos);
  }

  const isFullscreenSupported = !!document?.documentElement?.requestFullscreen;
  const onFullscreenClick = () => {
    if (!isFullscreenSupported) {
      setAlertOpen(true);
      setTimeout(() => setAlertOpen(false), 2000);
      return;
    }
    setIsFullscreen(!isFullscreen);
  }
  return <div className={"action-buttons " + (isMobile && "mobile")}>
    {!isMd &&
      <ContactButtons />
    }

{!isMd &&
      <HidePinButton />
    }
    {!isMd &&
      <SwitchLanguage />
    }

    {isMd &&
      <div className='action-btn alone top-btn' onClick={() => setOpen(!open)}>
        {!open && <DashboardIcon />}
        {open && <CloseDashboardIcon />}
      </div>
    }

    <AnimatePresence>
      {(!isMd || open) &&
        <>
          <motion.div initial={{ y: 0, opacity: 0 }}
            animate={{ y: [-20, 0], opacity: 1 }}
            exit={{ y: [0, -10], opacity: [0.8, 0] }}
            transition={{ duration: 0.3, ease: "backOut" }}>
            {isMd &&
              <div className='action-btn alone top-btn mt-[10px]'>
                <ContactButtons />
              </div>
            }
            {isMd &&
                <HidePinButton />
              }
              
            {!isMd &&
              <>
                <div className='action-group md:!mt-[10px]'>
                  <div className='action-btn alone'>
                    <a href='https://anarabinhtien.com'>
                      <HomeIcon />
                    </a>
                  </div>
                </div>
                <div className='action-group'>
                  <div className={`action-btn alone ${soundOn && 'active'}`}
                    onClick={() => setSoundOn(!soundOn)}><VolumeIcon /></div>
                </div>
              </>
            }

            <div className='action-group'>
              <div className='action-btn' onClick={onMoveUp}><UpIcon /></div>
              <div className='action-btn' onClick={onMoveDown}><DownIcon /></div>
              <div className='action-btn' onClick={onMoveLeft}><LeftIcon /></div>
              <div className='action-btn' onClick={onMoveRight}><RightIcon /></div>
            </div>

            <div className='action-group'>
              <div className='action-btn' onClick={() => onZoom(true)}><PlusIcon /></div>
              <div className='flex w-full'>
                <div className='h-[2px] bg-[white] w-[18px] mx-auto opacity-60'></div>
              </div>
              <div className='action-btn' onClick={() => onZoom(false)}><MinusIcon /></div>
            </div>

            <div className='action-group'>
              <Tooltip title={t('commingSoon')} placement="left" arrow>
                <div className='action-btn'><VrIcon /></div>
              </Tooltip>
              <div className={`action-btn ${autoSpin && 'active'}`} onClick={() => setAutoSpin(!autoSpin)}><SpinIcon /></div>
              {!isMobile && <div className={`action-btn ${isFullscreen && 'active'}`} onClick={onFullscreenClick}><FullScreenIcon /></div>}
              <div className='action-btn' onClick={() => setManualOpen(true)}><HelpIcon /></div>
              <FacebookShareButton
                url={BASE_URL}
                hashtag={"#anarabinhtien"}
              >
                <div className='action-btn'><ShareIcon /></div>
              </FacebookShareButton>
            </div>
          </motion.div>

          {alertOpen &&
            <motion.div className="fixed bottom-[80px] md:bottom-10 right-[6px] md:right-20 z-[1000]"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}>
              <Alert severity="warning">{t('fullscreenNotSupported')}</Alert>
            </motion.div>}

          {isMd &&
            <motion.div className='absolute top-0 right-[50px]'
              initial={{ x: 0, opacity: 0 }}
              animate={{ x: [20, 0], opacity: 1 }}
              exit={{ x: [0, 10], opacity: [0.8, 0] }}
              transition={{ duration: 0.3, ease: "backOut" }}>
              <div className='flex items-center'>
                <div className='action-group !mt-0 mr-3'>
                  <div className='action-btn alone'>
                    <a href='https://anarabinhtien.com'><HomeIcon /></a>
                  </div>
                </div>

                <div className='action-group !mt-0 mr-3'>
                  <div className={`action-btn alone ${soundOn && 'active'}`}
                    onClick={() => setSoundOn(!soundOn)}><VolumeIcon /></div>
                </div>
                
                <SwitchLanguage className="!mt-0"/>
              </div>
            </motion.div>
          }
        </>
      }
    </AnimatePresence>
  </div>
}

export default ActionButtons;