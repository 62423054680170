import './Layout.scss';
import MainButtons from "../MainButtons/MainButtons";
import ActionButtons from "../ActionButtons/ActionButtons";
import { LOGO_TEXT_URL } from '../../shared/constants/const';
import SoundPlay from '../SoundPlay/SoundPlay';
import Metadata from '../Metadata';
import React, { useContext, useEffect, useState, Suspense } from 'react';
import AppContext from '../../shared/contexts/AppContext';
import Photo2DViewer from '../Photo2DViewer/Photo2DViewer';
const Media = React.lazy(() => import('../Media/Media'));

const Layout = ({ children }) => {
  const { mediaOpen } = useContext(AppContext);
  const [shouldRenderMedia, setShouldRenderMedia] = useState(false);

  useEffect(() => {
    //Render Media after 5s to improve performance
    setTimeout(() => {
      setShouldRenderMedia(true);
    }, 3000);
  }, []);
  return (
    <div className="app-layout">
      {children}

      <img src={LOGO_TEXT_URL} alt=""
        className='fixed z-1 top-[26px] md:top-[30px] left-[80px] md:left-1/2 -translate-x-1/2 w-[120px] md:w-[200px]' />
      <ActionButtons />
      <MainButtons />
      {(shouldRenderMedia || mediaOpen) && <Suspense fallback={<></>}><Media /></Suspense>}
      <SoundPlay />
      <Metadata />
      <Photo2DViewer />
    </div>
  );
}

export default Layout;