import { useEffect, useState } from "react";

const useScreenSize = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMd, setIsMd] = useState(false);

  const handleResize = () => {
    const width = window.innerWidth;
    setIsMobile(width < 576);
    setIsMd(width < 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { isMobile, isMd };
}

export default useScreenSize;