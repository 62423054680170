import "./index.scss";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import flyImage from "../../assets/images/fly.png";
import {MEDIA_NODE_ID, OVERVIEW_NODE } from "../../shared/constants/const";
import useScreenSize from "../../hooks/useScreenSize";

const customHeight = {
  v46: 155,
  v48: 100,
  v49: 130,
  v47: 170,
  node4: {
    v49: 70,
    v48: 110,
  },
  node3: {
    v53: 100,
    v54: 120,
    v45: 210,
    v51: 100,
    v56: 190,
    v63: 220,
    v57: 110,
    v50: 120,
    v58: 200,
    v43: 100,
    v60: 210,
  },

  node2: {
    v53: 50,
    v52: 50,
    v51: 60,
    v50: 80,
    v44: 100,
    v58: 200,
    v59: 260,
    v60: 125,
    v63: 250,
    v57: 140,
    v56: 60,
  },

  node1: {
    v59: 80,
    v58: 120,
    v63: 180,
    v50: 50,
    v56: 60,
    v51: 170,
    v55: 260,
    v52: 80,
    v45: 170,
    v53: 110,
    v54: 140,
    v62: 210,
  },
  node7: {
    v50: 50,
    v59: 70,
    v58: 180,
    v44: 100,
    v61: 120,
    v43: 160,
    v52: 65,
    v53: 85,
    v54: 110,
    v45: 190,
    v55: 75,
    v62: 140,
    v56: 110,
    v57: 100,
  },
  node8: {
    v48: 150,
    v44: 120,
  },
};

const customZIndex = {
  v46: 110,
  v48: 110,
  v49: 80,
  v47: 40,
  node3: {
    v54: 150,
    v64: 150
  },
  node2: {
    v56: 120,
    v57: 101,
    v52: 101,
    v51: 101,
  },

  node1: {
    v54: 101,
    v53: 101,
    v59: 101,
  },
  node7: {
    v53: 101,
    v51: 130,
    v44: 130,
    v54: 101,
    v62: 101,
    v56: 101,
    v59: 162,
    v61: 161,
    v52: 150,
    v50: 170,
  },

  node8: {
    v49: 150,
  },
};

const TOUCH_HOLD_TIMEOUT = 300;
let touchHoldTimeout = null;

const AnchorNode = ({ data, id, style, ...restProps }) => {
  const { src, name, panorama, photo2DUrl, view360, divisionName } = data || {};
  const [open, setOpen] = useState(null);
  const [touchTime, setTouchTime] = useState(0);
  const [tooltip, setTooltip] = useState(name);
  const timeOut = useRef(null);
  const {i18n} = useTranslation();

  const { isMobile } = useScreenSize();
  useEffect(() => {
    setTooltip(name);
  }, [name]);

  useEffect(() => {
    setTooltip(name);
  }, [window.lang]);
  let curNode = window.panoHelper.pano.getCurrentNode();
  const isViewPanorama = OVERVIEW_NODE.includes(curNode)

  const handlePopoverOpen = () => {
    if (!isMobile && divisionName) {
      setTooltip(divisionName);
    }
    if (!!photo2DUrl) {
      return;
    }
    !panorama && setOpen(true);
  };
  const handlePopoverClose = () => {
    if (!isMobile && divisionName) {
      setTooltip(name);
    }
    !panorama && setOpen(false);
  };
  
  let height = customHeight[id] || 150;
  if (customHeight[curNode] && customHeight[curNode][id] != null) {
    height = customHeight[curNode][id];
  }

  let zIndex = customZIndex[id] || 100;
  if (customZIndex[curNode] && customZIndex[curNode][id] != null) {
    zIndex = customZIndex[curNode][id];
  }
  const isLeft = false;
  const isOverView = MEDIA_NODE_ID.includes(curNode);
  const isDivision = true;

  const onNodeClick = () => {
    if (isOverView) return;
    if (isDivision && divisionName) {
      if (isMobile) {
        clearTimeout(timeOut.current);
        setTooltip(tooltip == divisionName ? name : divisionName);
        timeOut.current =
          setTimeout(() => {
            setTooltip(name);
          }, 10000)
      }
    } else {
       // window.photo2DUrl = photo2DUrl;
    }
  };

  const handleTouchStart = () => {
    setTouchTime(new Date().getTime());
    touchHoldTimeout = setTimeout(() => {
      handlePopoverOpen();
    }, TOUCH_HOLD_TIMEOUT);
  };

  const handleTouchEnd = () => {
    const timeDiff = new Date().getTime() - touchTime; //Milisecond
    if (timeDiff < 200) {
      // Click
      onNodeClick();
    } else {
      // Hold click and show popover already => Close popover
      handlePopoverClose();
    }
    clearTimeout(touchHoldTimeout);
  };
  const handleTouchCancel = () => {
    handlePopoverClose();
    clearTimeout(touchHoldTimeout);
  };

  let smallName = ["v60", "v61","v58", "v57"].includes(id);
  return (
    <div
      id={id}
      {...restProps}
      className={
        "AnchorNode " +
        (open ? "visible" : "hiden") +
        (isOverView ? "overView" : "")
      }
      onMouseEnter={handlePopoverOpen}
      onTouchStart={handleTouchStart}
      onMouseLeave={handlePopoverClose}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchCancel}
      style={{
        ...style,
        transform: "translate(-19px, @y)".replace("@y", -38 - height + "px"),
        zIndex: open ? 200 : zIndex,
      }}
    >
      <div
        className={"marker-container center "}
        style={{ pointerEvents: "none" }}
      >
        <div className="extraDiv"> </div>
        <div
          className={"marker " + (!view360 ? "_2d" : "_3d")}
          onClick={onNodeClick}
          style={{ pointerEvents: "auto" }}
        >
          <img className="logo" src="/images/logo-symbol.webp" alt=""></img>
        </div>
        <div
          style={{
            width: 1,
            height,
            background: "white",
          }}
        ></div>
        <div
          style={{
            width: 6,
            height: 6,
            background: "white",
            borderRadius: "50%",
          }}
        ></div>
      </div>

      <div
        className={["card", open ? "visible" : "hiden", isLeft ? "" : ""].join(
          " "
        )}
        onClick={onNodeClick}
      >
        <img src={src} alt="dfs" className={!open ? "visible" : "hiden"} />
        {!open && (
          <div>
            <div
              className="name hiden"
              style={{ fontSize: smallName ? 12 : 14 }}
            >
              <Trans i18nKey={tooltip} />
            </div>
            {/* <div className="name hiden des">
              <Trans i18nKey={numCount} />
            </div> */}
          </div>
        )}
        <div className="big_name">
          <div className="name" style={{ fontSize: smallName ? 14 : 16 }}>
            <Trans i18nKey={tooltip} />
          </div>
          {/* <div style={{ display: "flex" }}>
            <TiLocation size={25} className="relative -left-[6px] -mr-[4px]" />
            <p className="location">
              <Trans i18nKey={shortDes} />
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export const AnchorNodeFly = ({ data, id, style, ...restProps }) => {
  const { src, name, panorama } = data || {};
  const [open, setOpen] = useState(null);
  const handlePopoverOpen = () => {
    !panorama && setOpen(true);
  };
  const handlePopoverClose = () => {
    !panorama && setOpen(false);
  };
  return (
    <div
      {...restProps}
      className={"AnchorNodeFly " + (open ? "visible" : "hiden")}
      onMouseEnter={handlePopoverOpen}
      onTouchStart={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      onTouchEnd={handlePopoverClose}
      onTouchCancel={handlePopoverClose}
      style={{
        ...style,
        zIndex: open ? 300 : customZIndex[id] || 100,
      }}
    >
      <img alt="fly" src={flyImage} style={{ maxWidth: "none" }} />
      <div className={["card", open ? "visible" : "hiden"].join(" ")}>
        <img src={src} alt="dfs" className={!open ? "visible" : "hiden"} />
        {!open && (
          <div>
            <div className="name hiden">
              <Trans i18nKey={name} />
            </div>
            {/* <div className="name hiden des">
              <Trans i18nKey={numCount} />
            </div> */}
          </div>
        )}
        <div className="big_name">
          <div className="name">
            <Trans i18nKey={name} />
          </div>
          {/* <div style={{ display: "flex" }}>
            <TiLocation size={25} className="relative -left-[6px] -mr-[4px]" />
            <p className="location">
              <Trans i18nKey={shortDes} />
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AnchorNode;
