export const LOGO_URL = '/images/logo.webp';
export const LOGO_NO_SLOGAN_URL = '/images/logo-no-slogan.webp';
export const LOGO_SYMBOL_URL = '/images/logo-symbol.webp';
export const LOGO_TEXT_URL = '/images/logo-text.webp';
export const PHONE_NUMBER = '093 680 09 09';
export const SOUND_URL = '/sounds/s01.mp3';
export const MIN_ZOOM = 30;
export const MAX_ZOOM = 140;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const OVERVIEW_NODE_ID = 'node3';
export const AREA_NODE_ID = 'node7';
export const MEDIA_NODE_ID = 'node8';
export const OVERVIEW_NODE = ["node1", "node2", "node3"]
