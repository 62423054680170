import { useContext } from "react";
import AppContext from '../../shared/contexts/AppContext';
import { IoCloseOutline } from "react-icons/io5";

const Photo2DViewer = () => {
  const { photo2DViewingUrl, setPhoto2DviewingUrl } = useContext(AppContext);

  const handleClose = () => {
    setPhoto2DviewingUrl(null);
    window.photo2DUrl = null;
  }

  return (
    <>
      <div className={`fixed top-0 left-0 w-full h-full z-[9] ${!!photo2DViewingUrl ? 'flex' : 'hidden'} bg-[#000000b3] py-2 md:py-4 px-1 md:px-8`} onClick={handleClose}>
        <div className="absolute top-2 right-2 text-white primary-color-hover cursor-pointer p-2 z-[9]" onClick={handleClose}>
          <IoCloseOutline size={30}/>
        </div>
        <div className="m-auto flex w-full h-full">
          <img src={photo2DViewingUrl} className="max-w-full max-h-full m-auto"
            onClick={e => e.stopPropagation()}
            />
        </div>
      </div>
    </>
  );
}

export default Photo2DViewer;