export const HomeIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="calc(1.5em * 18 / 20)" viewBox="0 0 20 18" fill="none">
    <path d="M16.25 7.33337V16.5H3.75V7.33337" stroke="currentColor" strokeWidth="1.5" />
    <path d="M16.25 8.16667H17.5L10 1.5L2.5 8.16667H3.75" stroke="currentColor" strokeWidth="1.5" />
    <path d="M10 12.3334H10.0075" stroke="currentColor" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
  </svg>
};

export const VolumeIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="calc(1.4em * 16 / 20)" viewBox="0 0 20 16" fill="none">
    <path d="M14.1667 5.5C14.6878 6.18307 15 7.05283 15 8C15 8.94717 14.6878 9.81692 14.1667 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
    <path d="M16.6667 3.83337C17.709 4.97182 18.3333 6.42146 18.3333 8.00004C18.3333 9.57862 17.709 11.0283 16.6667 12.1667" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
    <path d="M1.72363 10.9329L5.83822 10.9099L11.639 14.6634C11.6944 14.6998 11.6747 14.6235 11.6747 14.5571V8.00975V1.45261C11.6747 1.38629 11.6944 1.3099 11.639 1.34634L5.83822 5.09986L1.72363 5.07681C1.67761 5.07681 1.66504 5.11249 1.66504 5.15852V8.00731V10.8512C1.66504 10.8972 1.67761 10.9329 1.72363 10.9329Z" stroke="currentColor" strokeWidth="1.5" />
  </svg>
};

export const UpIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="calc(1.2em * 10 / 16)" height="1.2em" viewBox="0 0 10 16" fill="none">
    <path d="M10 5.89443L8.8215 7.06778L5.83382 4.09324L5.83387 15.0834L4.16721 15.0834L4.16715 4.09288L1.17852 7.06786L-4.01657e-07 5.89453L5.00028 0.916706L10 5.89443Z" fill="currentColor" />
  </svg>
};

export const DownIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="calc(1.2em * 10 / 16)" height="1.2em" viewBox="0 0 10 16" fill="none">
    <path d="M-1.63243e-07 10.1056L1.1785 8.93222L4.16618 11.9068L4.16613 0.916626L5.83279 0.916626L5.83284 11.9071L8.82148 8.93214L10 10.1055L4.99972 15.0833L-1.63243e-07 10.1056Z" fill="currentColor" />
  </svg>
};

export const LeftIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="calc(1.2em * 10 / 16)" viewBox="0 0 16 10" fill="none">
    <path d="M5.89438 -5.64905e-07L7.06773 1.1785L4.0932 4.16618L15.0833 4.16613L15.0833 5.83279L4.09283 5.83284L7.06782 8.82148L5.89448 10L0.916661 4.99972L5.89438 -5.64905e-07Z" fill="currentColor" />
  </svg>
};

export const RightIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="calc(1.2em * 10 / 16)" viewBox="0 0 16 10" fill="none">
    <path d="M10.1056 10L8.93227 8.8215L11.9068 5.83382L0.916672 5.83387L0.916672 4.16721L11.9072 4.16716L8.93218 1.17852L10.1055 0L15.0833 5.00028L10.1056 10Z" fill="currentColor" />
  </svg>
};

export const PlusIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.95833 5.95837V0.333374H8.04166V5.95837H13.6667V8.04171H8.04166V13.6667H5.95833V8.04171H0.333328V5.95837H5.95833Z" fill="currentColor" />
  </svg>
};

export const MinusIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="calc(1em * 4 / 14)" viewBox="0 0 14 4" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.333323 0.958374L13.6667 0.958374V3.04171L0.333323 3.04171V0.958374Z" fill="currentColor" />
  </svg>
};

export const VrIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="calc(1em * 20 / 14)" height="1em" viewBox="0 0 20 14" fill="none">
    <path d="M2.05522 4.49996H17.8204M1.66667 4.49996V12.8333H7.50001L10 10.3333L12.5 12.8333H18.3333V4.49996L15 1.16663H5.00001L1.66667 4.49996Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
    <path d="M4.16667 7H6.66667" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
  </svg>
};

export const SpinIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="1.1em" height="1.1em" viewBox="0 0 18 18" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.00001 2.33329C8.48965 2.33329 7.99367 2.39049 7.51774 2.49848L7.14893 0.873132C7.74505 0.737867 8.36471 0.666626 9.00001 0.666626C13.6024 0.666626 17.3333 4.39759 17.3333 8.99996C17.3333 11.0683 16.5794 12.9604 15.3328 14.4166H16.9167V16.0833H12.3139V11.5H13.9806V13.4317C15.0301 12.2533 15.6667 10.7009 15.6667 8.99996C15.6667 5.31806 12.6819 2.33329 9.00001 2.33329ZM1.08334 1.92958H5.66667V6.49996H4.00001V4.5901C2.96209 5.76585 2.33334 7.30957 2.33334 8.99996C2.33334 12.6819 5.31811 15.6666 9.00001 15.6666C9.51036 15.6666 10.0063 15.6094 10.4823 15.5014L10.8511 17.1268C10.255 17.2621 9.6353 17.3333 9.00001 17.3333C4.39763 17.3333 0.666672 13.6023 0.666672 8.99996C0.666672 6.93773 1.41611 5.05071 2.65609 3.59625H1.08334V1.92958Z" fill="currentColor" />
  </svg>
};

export const FullScreenIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 18 18" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.7436 5.07739L5.07693 11.7441L6.25545 12.9226L12.9221 6.2559L11.7436 5.07739Z" fill="currentColor" />
    <path d="M17.3329 17.3334H11.4996V15.6667H14.4878L10.6661 11.8451L11.8446 10.6666L15.6662 14.4881V11.5H17.3329V17.3334Z" fill="currentColor" />
    <path d="M3.51137 2.33341L7.33279 6.15483L6.15428 7.33334L2.33279 3.51185L2.33279 6.50008H0.666122V0.666748H6.49946V2.33341L3.51137 2.33341Z" fill="currentColor" />
  </svg>
};

export const HelpIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 20 20" fill="none">
    <circle cx="10" cy="9.99996" r="8.33333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
    <path d="M7.91667 8.33333C7.91667 7.18274 8.84941 6.25 10 6.25C11.1506 6.25 12.0833 7.18274 12.0833 8.33333C12.0833 9.48393 11.1506 10.4167 10 10.4167V12.0833M10 13.3333V14.5833" stroke="currentColor" strokeWidth="1.5" />
  </svg>
};

export const DashboardIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 12" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 1.5L-1.16564e-07 1.5L0 0L12 1.18021e-06L12 1.5Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 6.75L-1.16564e-07 6.75L0 5.25L12 5.25L12 6.75Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 12L-1.16564e-07 12L0 10.5L12 10.5L12 12Z" fill="white" />
  </svg>
};

export const CloseDashboardIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.00001 5.55184L12.0102 0.541626L13.4583 1.98975L8.44813 6.99996L13.4583 12.0102L12.0102 13.4583L7.00001 8.44808L1.98979 13.4583L0.541672 12.0102L5.55188 6.99996L0.541672 1.98975L1.98979 0.541626L7.00001 5.55184Z" fill="white" />
  </svg>
};

export const ContactIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 18 18" fill="none">
    <path d="M3.8115 8.80927C4.4603 9.87807 5.26835 10.8977 6.18539 11.814C7.10163 12.731 8.12117 13.5392 9.18975 14.188L11.8732 11.3091L17.0618 14.2642C16.3087 15.6623 14.5027 17.4504 12.2081 16.9881C11.1238 16.7693 10.0537 16.4047 8.75972 15.6623C7.45729 14.9151 6.22756 13.9607 5.13498 12.8673C4.0416 11.7748 3.0842 10.542 2.33696 9.23955C1.59456 7.94557 1.22996 6.8755 1.01113 5.79116C0.548898 3.4966 2.33696 1.69055 3.73509 0.937492L6.69015 6.12612L3.8115 8.80927Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.75 4.35V2.25H11.25V4.35H9.75ZM12.1974 4.74268L13.6823 3.25776L14.743 4.31842L13.2581 5.80334L12.1974 4.74268ZM13.65 6.75H15.75V8.25H13.65V6.75Z" fill="white" />
  </svg>
};


export const ShareIcon = ()=>{
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="18px" height="18px" fillRule="nonzero">
    <g fill="#ffffff" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{mixBlendMode: "normal"}}>
      <g transform="scale(10.66667,10.66667)">
        <path d="M18,2c-1.65685,0 -3,1.34315 -3,3c0.00061,0.18815 0.01892,0.37583 0.05469,0.56055l-7.11523,4.15039c-0.54194,-0.45909 -1.22919,-0.71102 -1.93945,-0.71094c-1.65685,0 -3,1.34315 -3,3c0,1.65685 1.34315,3 3,3c0.70916,-0.00139 1.3949,-0.25396 1.93555,-0.71289l7.11914,4.15234c-0.03577,0.18472 -0.05408,0.3724 -0.05469,0.56055c0,1.65685 1.34315,3 3,3c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3c-0.70983,0.00093 -1.39634,0.25353 -1.9375,0.71289l-7.11719,-4.15234c0.03577,-0.18472 0.05408,-0.3724 0.05469,-0.56055c-0.00061,-0.18815 -0.01892,-0.37583 -0.05469,-0.56055l7.11523,-4.15039c0.54194,0.45909 1.22919,0.71102 1.93945,0.71094c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3z"></path>
      </g>
    </g>
  </svg>
};