import { useContext } from "react";
import AppContext from "../../shared/contexts/AppContext";
import { useEffect } from "react";
import { useState } from "react";
import { SOUND_URL } from "../../shared/constants/const";

const SoundPlay = () => {
  const {soundOn} = useContext(AppContext);
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    if (soundOn) {
      if (!audio) {
        const newAudio = new Audio(SOUND_URL);
        newAudio.preload = "none"; // Prevent load audio early to improve performance
        setAudio(newAudio);
        newAudio.play();
      } else {
        audio.play();
      }
    } else {
      if (audio) {
        audio.pause();
      }
    }
  }, [soundOn]);
}

export default SoundPlay;